import { finishKyc } from './eidEasy'
import { idenfyInit } from './idenfy'
import { getUserInfo, getVpSessionUsers, getVpSessionUserToken } from './oidc'
import {
  createMember, deleteMember, getMembers, updateMember,
  resendSuccessCreateMember, getOrganization, getMember
} from './organizations'
import { addSealMember, createSeal, deleteMemberFromSeal, deleteSeal, getSeal, getSealMembers, getSeals } from './seals'
import { createMailGroup, deleteMailGroup, getMailGroup, getMailGroups } from './mailGroups'
import { deleteToken, generateToken, getTokens, updateToken } from './tokens'

import {
  changePassword,
  createUser,
  getUserMetadata,
  resetPassword,
  startPasswordReset,
  updateUserMetadata,
  resendSuccessCreateUser,
  getPasswordChangeDate,
} from './users'

export * from './eidEasy'
export * from './instance'
export * from './oidc'
export * from './types'
export * from './users'
export * from './idenfy'
export * from './tokens'
export * from './organizations'
export * from './seals'
export * from './mailGroups'


const auth = {
  getPasswordChangeDate,
  changePassword,
  createMember,
  createUser,
  deleteMember,
  deleteToken,
  finishKyc,
  generateToken,
  getMembers,
  getOrganization,
  getTokens,
  getUserInfo,
  getUserMetadata,
  idenfyInit,
  resendSuccessCreateMember,
  resendSuccessCreateUser,
  resetPassword,
  startPasswordReset,
  updateMember,
  updateToken,
  updateUserMetadata,
  getMember,
  // seals
  createSeal,
  getSeals,
  getSeal,
  deleteSeal,
  getSealMembers,
  addSealMember,
  deleteMemberFromSeal,
  // * mail groups
  createMailGroup,
  deleteMailGroup,
  getMailGroup,
  getMailGroups,
  // * vp login session
  getVpSessionUsers,
  getVpSessionUserToken
}

export default auth;
