import React, { useState } from 'react';
import { Avatar, Caption, Label } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { Invite, RevokeInviteError } from 'apis/contract';
import { transformDate } from 'utils';
import Stamp from 'components/Stamp/Stamp';
import { errorParser } from 'utils/requests/errorParser';
import styles from './InviteRow.module.scss';

interface Props {
  invite: Invite;
  isOwner?: boolean
  onRevoke: (invite: Invite) => Promise<void>
  onReInvite: (invite: Invite) => Promise<void>
}


export default function InviteRow({ invite, isOwner, onRevoke, onReInvite }: Props) {
  const [reInviteLoading, setReInviteLoading] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { LL } = useI18nContext();

  const handleRevoke = async () => {
    setRevokeLoading(true)
    await onRevoke(invite)
      .finally(() => setRevokeLoading(false))
      .catch((e) => errorParser(e, err => {
        setError(LL.errors.contract.revokeInviteErrors[err.response?.data.message as RevokeInviteError]())
      }))
  }

  const handleReInvite = async () => {
    setReInviteLoading(true)
    await onReInvite(invite)
      .finally(() => setReInviteLoading(false))
      .catch((e) => errorParser(e, err => {
        setError(LL.errors.contract.revokeInviteErrors[err.response?.data.message as RevokeInviteError]())
      }))
  }

  return (
    <div className={styles.row}>
      <Avatar width={40} name={invite.name || invite.email} />
      <div className={styles.nameColumn}>
        <Label>{invite.sub ? `${invite.name} ${invite.username === invite.email ? '' : `(${invite.username})`}` : invite.email}</Label>
        <Caption small>{invite.email}</Caption>
      </div>
      <Stamp
        status='invited'
        loading={revokeLoading || reInviteLoading}
        children={error || transformDate(invite.createdAt).substring(0, 16)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...isOwner && {
          onRevoke: () => !revokeLoading && handleRevoke(),
          onReInvite: () => !reInviteLoading && handleReInvite()
        }}
      />
    </div>
  )
}
