/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Input, useForm, SelectInstance, FormSelectAsync } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ApplicationState } from "modules";
import authApi from "apis/auth";
import { InviteeDetailed } from "apis/contract";
import inviteeValidation from "./inviteValidation";
import styles from './InviteSelector.module.scss';

interface Props {
  invitees: InviteeDetailed[]
  owner: InviteeDetailed
  currentParticipants: InviteeDetailed[]
  errorMessage?: string
  updateList: (invitees: InviteeDetailed[]) => void
  clearErrors?: () => void
}

// * sort values so groups would be first
// * value: email split with ,
// * DONE: on validation - accept comma-separated values
// * DONE: what if one of group is already invited????
// * DONE: after validation - return type? and add multiple values

export default function InviteSelector({ invitees, currentParticipants, owner, updateList, errorMessage, clearErrors }: Props) {
  const form = useForm();
  const { LL } = useI18nContext();
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub);

  const selectRef = useRef<SelectInstance<false> | null>(null);
  const abortControllerRef = useRef(new AbortController());
  
  const [inputValue, setInputValue] = useState<string>();
  const [localErrorMessage, setLocalErrorMessage] = useState<string>();

  const loadSuggestions = useCallback(async (search: string, _: any, additional: any) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    const [mailGroupsResp, membersResp] = await Promise.all([
      authApi.getMailGroups(
        { bookmark: additional?.mailGroupsBookmark, substring: search, pageSize: 50, order: 'asc', sortBy: 'name' },
        abortControllerRef.current.signal
      ),
      authApi.getMembers(
        { bookmark: additional?.membersBookmark, substring: search, pageSize: 7, order: 'asc', sortBy: 'name' },
        abortControllerRef.current.signal
      )
    ])

    const { data: mailGroups } = mailGroupsResp;
    const { data: members } = membersResp;

    // TODO: clean up at backend + store session in redis with common bookmark and what is filtered? check in outlook
    const cleanedMembers = members.items.filter(member => (
        member.sub !== userSub
        // * leave only comparison if same sub is invited (or same email, if sub is not present)
        && !invitees.some(invitee => invitee.sub && invitee.sub === member.sub)
        // && !currentParticipants.includes(member.email)
    ));

    // const uniqueMemberEmails = [...new Set(cleanedMembers.map(member => member.email))];

    const options = []
    
    if (additional?.shouldLoadGroups) {
      options.push(
        ...mailGroups.items.filter(group => group.emails.length).map(mailGroup => ({
          label: `👥 ${mailGroup.name}`,
          value: JSON.stringify({
            email: mailGroup.emails.join(',')
          })
        }))
      )
    }

    options.push(
      ...members.items
        .filter(member => member.enabled)
        .map(({ firstName, lastName, sub, email, username }) => ({
          value: JSON.stringify({
            sub,
            email,
            username,
            name: `${firstName} ${lastName}`
          }),
          label: `${firstName} ${lastName} ${username === email ? '' : `(${username})`} <${email}>`
        }))
    );
    
    return {
      options,
      hasMore: members.items.length > 10 || members.bookmark !== '',
      additional: {
        membersBookmark: members.bookmark,
        mailGroupsBookmark: mailGroups.bookmark,
        shouldLoadGroups: false,
      },
    };
  }, [abortControllerRef, invitees])

  const addInvitees = (newInvitees: InviteeDetailed[]) => {
    form.clearErrors();
    setLocalErrorMessage(undefined);

    const newInviteesFiltered: InviteeDetailed[] = [];
    let errorMsg = ''

    newInvitees.forEach(invitee => {
      const error = inviteeValidation(invitee, owner, invitees, currentParticipants)
      if (!error) {
        newInviteesFiltered.push(invitee);
      } else {
        errorMsg += `${error}; `;
      }
    })

    if (errorMsg) {
      setLocalErrorMessage(errorMsg);
      form.setError('invitee', { message: errorMsg });
    }

    updateList([...invitees, ...newInviteesFiltered]);
    // setInputValue(undefined);
    // selectRef.current?.clearValue()
  }

  const addInviteesFromInput = () => {
    form.clearErrors();
    setLocalErrorMessage(undefined);

    if (!inputValue) return;
    const newInvitees: InviteeDetailed[] = [];
    let errorMsg = ''

    // * ";" "," and " " are treated as separators for multi email input, also trim excess spaces on sides
    const inputValues = [
      ...new Set(
        inputValue
          .replace(';', ',')
          .replace(' ', ',')
          .split(',')
          .map(s => s.trim())
      )
    ]
    inputValues.forEach(email => {
      const error = inviteeValidation({ email }, owner, invitees, currentParticipants)
      if (!error) {
        newInvitees.push({ email });
      } else {
        errorMsg += `${error}; `;
      }
    })

    if (errorMsg) {
      setLocalErrorMessage(errorMsg);
      form.setError('invitee', { message: errorMsg });
    }
    updateList([...invitees, ...newInvitees]);
    setInputValue(undefined);
    selectRef.current?.clearValue()
  }

  // * pass sub and email to API
  // * if sub present - api will fetch user and fill in the name 

  // * click on mail group adds all emails to the list
  // * click on a regular user adds this exact user to the list with its sub
  // * input field and add button are only for custom emails

  return (
    <div className={styles.wrap}>
      <div className={[styles.addLine, !form.formState.isValid && styles.error].join(' ')}>
        {/* <Input
          name='invitee'
          title={LL.documentsPage.inviteTitle()}
          placeholder={LL.documentsPage.invitePlaceholder()}
          errorMessage={uploadError}
          onChange={() => { form.clearErrors(); if (clearErrors) clearErrors() }}
          formHook={form}
        /> */}
        <FormSelectAsync
          name='invitee'
          formHook={form}
          isMulti={false}
          isCreatable={false}
          // value={inputValue && { value: inputValue, label: inputValue } as any}
          inputValue={inputValue}
          className={styles.select}
          placeholder={LL.documentsPage.invitePlaceholder()}
          noOptionsMessage={() => null}
          selectRef={selectRef}
          debounceTimeout={300}
          cacheUniqs={[invitees]}
          loadOptions={loadSuggestions}
          additional={{ membersBookmark: '', mailGroupsBookmark: '', shouldLoadGroups: true }}
          // * onClick fill in the values, even if it is mail group or user
          // * so the plus button only for custom emails
          onChange={(val, action) => {
            // console.log(val)
            // console.log(action)
            
            if (action.action === 'select-option') {
              const inviteeValue = JSON.parse(val?.value || '') as InviteeDetailed;
              addInvitees(inviteeValue.sub ? [inviteeValue] : inviteeValue.email.split(',').map(v => ({ email: v })))
            } else {
              setInputValue(undefined)
            }
            // * JSON.parse value
            // * if type: membergroup - add as a bunch of emails
            // * if type: user - add with sub
            // return action.action !== 'clear' ? val && setInputValue(val.value) : setInputValue(undefined)
          }}
          onInputChange={(val, action) => action.action !== 'input-blur' && action.action !== 'menu-close' && setInputValue(val)}
          errorMessage={localErrorMessage || errorMessage}
          title={LL.documentsPage.inviteTitle()}
        />
        <Button icon='add' onClick={addInviteesFromInput} />
      </div>
      {invitees.length > 0  && (
        <div className={styles.list}>
          {invitees.map(({email, sub, username, name}) => (
            <Input
              key={sub || email}
              disabled
              name={sub || email}
              formHook={form}
              value={sub ? `${name} ${username === email ? '' : `(${username})`} <${email}>` : email}
              suffix='close'
              onSuffix={() => {updateList(invitees.filter(invitee =>invitee.email !== email || invitee.sub !== sub))}}
            />
          ))}
        </div>
      )}
    </div>
  );
}
