const delay = (ms: number, ac?: AbortController) => new Promise((resolve, reject) => {
  let timer: NodeJS.Timeout;

  const listener = () => {
    clearTimeout(timer);
    reject(ac?.signal.reason);
  };

  ac?.signal.throwIfAborted();

  timer = setTimeout(() => {
    ac?.signal.removeEventListener('abort', listener);
    resolve('resolved');
  }, ms);

  ac?.signal.addEventListener('abort', listener);
})

export default delay