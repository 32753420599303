import { InviteeDetailed } from "apis/contract";
import { EMAIL_REG_EXP } from "utils/consts";


// Add transaltion
export default function inviteeValidation(
  invitee: InviteeDetailed, owner: InviteeDetailed, invitees: InviteeDetailed[], existingUsers: InviteeDetailed[]
) {
  if (!invitee.email.match(EMAIL_REG_EXP))
    return `"${invitee.email}" is invalid email`
  if (invitees.some(i => i.email === invitee.email && i.sub === invitee.sub))
    return `"${JSON.stringify(invitee)}" is already entered`
  if (existingUsers.some(i => i.email === invitee.email && i.sub === invitee.sub))
    return `"${JSON.stringify(invitee)}" is already invited`
  // if (invitee.email === owner.email && invitee.sub === owner.sub)
  //   return `"${JSON.stringify(invitee)}" is the owner`
  return null
}