import { AiPreferences } from "apis/aiApi"
import { UserDetailed, UserMetadata } from "apis/auth"
import { DocumentTypesConfig } from "apis/contract"
import { AxiosError } from "axios"

export enum ActionTypes {
  AUTHENTICATE = '@@authentication/AUTHENTICATE',
  INITIALISE = '@@authentication/INITIALISE',
  RESET = '@@authentication/RESET',
  LOG_OUT = '@@authentication/LOG_OUT',
  SET_USER_ERROR = '@@authentication/SET_USER_ERROR',
  SET_USER_INFO = '@@authentication/SET_USER_INFO',
  SET_METADATA = '@@authentication/SET_METADATA',
  SET_DOCUMENT_INVITES_COUNT = '@@authentication/SET_DOCUMENT_INVITES_COUNT',
  SET_PRM_INVITES_COUNT = '@@authentication/SET_PRM_INVITES_COUNT',
  SET_DOCUMENT_TYPES = '@@authentication/SET_DOCUMENT_TYPES',
  SET_AI_PREFERENCES = '@@authentication/SET_AI_PREFERENCES',
}

export interface InitializeAction {
  type: ActionTypes.INITIALISE
}
export interface ResetInitAction {
  type: ActionTypes.RESET
}

export interface AuthenticateAction {
  type: ActionTypes.AUTHENTICATE,
  accessToken: string
  refreshTokenAbortController: AbortController
}

export interface SetUserAction {
  type: ActionTypes.SET_USER_INFO,
  user: UserDetailed
}

export interface SetUserError {
  type: ActionTypes.SET_USER_ERROR,
  error: AxiosError
}

export interface LogoutAction {
  type: ActionTypes.LOG_OUT,
}

export interface SetMetadataAction {
  type: ActionTypes.SET_METADATA,
  metadata: UserMetadata
}
export interface SetDocumentInvitesCountAction {
  type: ActionTypes.SET_DOCUMENT_INVITES_COUNT,
  documentInvitesCount: number
}
export interface SetPrmInvitesCountAction {
  type: ActionTypes.SET_PRM_INVITES_COUNT,
  prmInvitesCount: number
}
export interface SetDocumentTypesAction {
  type: ActionTypes.SET_DOCUMENT_TYPES,
  documentTypes: DocumentTypesConfig
}
export interface SetAiPreferencesAction {
  type: ActionTypes.SET_AI_PREFERENCES,
  aiPreferences: AiPreferences
}


export type AuthenticationAction =
  AuthenticateAction |
  InitializeAction |
  ResetInitAction |
  SetUserAction |
  SetUserError |
  LogoutAction |
  SetMetadataAction |
  SetDocumentInvitesCountAction |
  SetPrmInvitesCountAction |
  SetDocumentTypesAction |
  SetAiPreferencesAction

export interface AuthenticationState {
  readonly authenticated: boolean
  readonly initialized: boolean
  readonly error?: AxiosError
  readonly user?: UserDetailed
  readonly metadata?: UserMetadata
  readonly aiPreferences?: AiPreferences
  readonly documentInvitesCount?: number
  readonly prmInvitesCount?: number
  readonly documentTypes?: DocumentTypesConfig
  readonly refreshTokenAbortController?: AbortController
}
