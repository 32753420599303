import React, { useContext } from 'react';
import { revokeInvite, reInvite, Invite } from 'apis/contract';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules';
import { useI18nContext } from 'i18n/i18n-react';
import canUserDo from 'utils/unique/canUserDo';
import { Skeleton } from '@sis-lab/web-ui-components';
import { getHasSigned, getIsInvited } from './components/utils';
import SignatureRow from './components/SignatureRow/SignatureRow';
import InviteRow from './components/InviteRow/InviteRow';
import SignRow from './components/SignRow/SignRow';
import styles from './ParticipantTab.module.scss';
import DocumentContext from '../../DocumentContext/DocumentContext';

interface Props {
  isValidator?: boolean
  isOwner: boolean
}


export default function ParticipantsTab({ isValidator, isOwner }: Props) {
  const { LL } = useI18nContext();
  const { document, setDocument } = useContext(DocumentContext);
  const user = useSelector((state: ApplicationState) => state.authentication.user);
  const hasSigned = getHasSigned(document?.signatures, user?.sub);
  const isInvited = getIsInvited(document, user?.sub, user?.email);
  const noSignatures = document?.invites.length === 0 && document?.signatures.length === 0;
  const notAllowedToSign = !!user?.permissions && !canUserDo(user, 'allowedToSign');

  const onRevoke = async ({ email, sub }: Invite) => {
    if (!document) return
    await revokeInvite(document.fingerprint, { email, sub })
      .then(({ data }) => setDocument({
        ...document,
        invites: document.invites.filter((i) =>{
          const isEmailMatch = i.email === email;
          const isSubMatch = i.sub === sub;
          const hasNoSub = !i.sub;
        
          return sub
            ? !(isSubMatch && isEmailMatch) // * remove invite with email + sub
            : !(isEmailMatch && hasNoSub); // * remove only invite with matching email
        }),
        history: [...document.history, data]
      }))
  }

  const handleReInvite = async ({ email, sub }: Invite) => {
    if (!document) return
    await reInvite(document.fingerprint || '', [ { email,sub } ])
      .then(({data}) => setDocument({
        ...document,
        history: document.history.concat(data.events)
      }));
  }
  
  if (!document) return <Skeleton height={70} />
  return  (
    <>
      <SignRow fingerprint={document.fingerprint} hidden={hasSigned || !isInvited || !!isValidator || notAllowedToSign} />
      {document.signatures.map(signature => <SignatureRow key={signature.createdAt} signature={signature} />)}
      {!isValidator && document.invites
      // .filter(invite => invite.sub ? invite.sub !== user?.sub : invite.email !== user?.email || isOwner)
        // .filter(i => {
        //   const isEmailMatch = i.email === user?.email;
        //   const isSubMatch = i.sub === user?.sub;
        //   const hasNoSub = !i.sub;
          
        //   return i.sub
        //     ? !(isSubMatch && isEmailMatch) // * remove invite with email + sub
        //     : !(isEmailMatch && hasNoSub)
        //     || isValidator;
        // })
        .map(invite =>
          <InviteRow
            key={invite.createdAt}
            invite={invite}
            onRevoke={onRevoke}
            onReInvite={handleReInvite}
            isOwner={isOwner}
          />
        )
      }
      {noSignatures && !(!hasSigned && isInvited && !notAllowedToSign) && (
        <p className={styles.noSignatures}>{LL.participantTab.noSignatures()}</p> 
      )}
    </>
  );
}
