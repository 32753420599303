import { objectToQuery } from "utils";
import { PaginatedResponse } from "types";
import { instance } from "../instance";
import { MemberSearch, SealSearch, Seal } from "./types";
import { Member } from "../types";

export const createSeal = (email: string, name: string) => (
  instance.post<Seal>('/organizations/seals', { email, name })
)

export const getSeals = (search: SealSearch, signal?: AbortSignal) => (
  instance.get<PaginatedResponse<Seal>>(`/organizations/seals${objectToQuery(search)}`, { signal })
)

export const getSeal = (id: string) => (
  instance.get<Seal>(`/organizations/seals/${id}`)
)

export const deleteSeal = (id: string) => (
  instance.delete(`/organizations/seals/${id}`)
)

export const getSealMembers = (sealSub: string, search: MemberSearch, signal: AbortSignal) => (
  instance.get<PaginatedResponse<Member>>(`/organizations/seals/${sealSub}/members${objectToQuery(search)}`, { signal })
)

export const addSealMember = (sealSub: string, memberSub: string) => (
  instance.put<Member>(`/organizations/seals/${sealSub}/members`, { sub: memberSub })
)

// sub inside body? 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteMemberFromSeal = (sealSub: string, memberSub: string) => (
  instance.delete(`/organizations/seals/${sealSub}/members`, { data: { sub: memberSub } })
)
