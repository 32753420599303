import React, { useContext, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { ApplicationState } from 'modules';
import { Button, Divider, Modal, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { addInvites, AddInvitesError, Invitee } from 'apis/contract';
import { errorParser } from 'utils/requests/errorParser';
import InviteSelector from 'components/InviteSelector/InviteSelector';
import DocumentContext from '../../DocumentContext/DocumentContext';
import styles from './InviteModal.module.scss';

interface Props {
  fingerprint: string;
  btnClassName?: string;
}


export default function InviteModalButton({ fingerprint, btnClassName }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [invitees, setInvitees] = useState<Invitee[]>([]);
  const [isLoading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { document, setDocument } = useContext(DocumentContext)
  const { LL } = useI18nContext();
  // const user = useSelector((state: ApplicationState) => state.authentication.user)
  const form = useForm();

  const currentParticipants = document ? [
    ...document.signatures.map(({creator: { sub, email }}) => ({ email, sub })), 
    ...document.invites.map(({sub, email}) => ({ email, sub }))
  ] : []

  const onCancel = () => {
    setInvitees([]);
    setErrorMessage('');
    setIsOpen(false);
  }

  const invite = async () => {
    await form.clearErrors();
    setIsloading(true);

    addInvites(fingerprint, invitees)
      .then(({ data }) => {
        if (!document) return
        setDocument({
          ...document,
          invites: document.invites.concat(data.invites),
          history: document.history.concat(data.events),
        })
        onCancel();
      })
      .finally(() => setIsloading(false))
      .catch(err => errorParser(err, e => {
        const error = e.response?.data.message;
        const errorDetails = e.response?.data.details as string[]
        if(error && errorDetails){
          setErrorMessage(LL.errors.contract.addInvitesErrors[error as AddInvitesError](errorDetails.join(', ')))
        } else {
          setErrorMessage(error)
        } 
      }))
  }

  return (
    <div>
      <Button
        children={LL.participantTab.inviteToSignButton()}
        className={btnClassName}
        mobileIcon='person_add'
        onClick={() => setIsOpen(true)}
        suffix='person_add'
        type='secondary'
      />
      <Modal
        onCancel={onCancel}
        open={isOpen}
        size='medium'
        closable={false}
        footer={null}
        className={styles.modal}
        bodyStyle={{ overflow: 'visible' }}
      >
        <InviteSelector 
          invitees={invitees}
          owner={{ email: document?.creator.email || '', sub: document?.creator.sub }}
          //  * real owner should be here
          errorMessage={errorMessage}
          clearErrors={(() => setErrorMessage(undefined))}
          currentParticipants={currentParticipants}
          updateList={setInvitees}
        />
        {invitees.length === 0 && <div className={styles.filler} />}
        <Divider size='tiny' />
        <div className={styles.footer}>
          <Button
            type='secondary'
            children={LL.commonButtons.cancel()}
            disabled={isLoading}
            onClick={onCancel}
          />
          <Button
            type='primary'
            children={LL.inviteModal.inviteButton()}
            suffix={isLoading ? 'loading' : undefined}
            disabled={isLoading || invitees.length === 0}
            onClick={invite}
          />
        </div>
      </Modal>
    </div>
  )   
}
