import auth, {Member, MemberSearch, SealSearch } from "apis/auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { errorParser } from "utils/requests/errorParser";
import { ApplicationState } from "modules";
import { useSelector } from "react-redux";


export default function useFetchMembers(sealSub: string) {
  const [members, setMembers] = useState<Member[]>([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub);
  const bookmarkRef = useRef<string>();
  const abortControllerRef = useRef(new AbortController());

  const getMoreMembers = useCallback(async (search: MemberSearch) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    setLoading(true)
    auth.getSealMembers(sealSub ,{ bookmark: bookmarkRef.current, ...search }, abortControllerRef.current.signal)
      .then(({ data }) => {
        if (data.items.length < 10 || data.bookmark === '') setFullyLoaded(true)
        setMembers(members.concat(data.items))
        bookmarkRef.current = data.bookmark
      })
      .finally(() => setLoading(false))
      .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [members, bookmarkRef.current, abortControllerRef])

  const searchMembers = useCallback(async (search: SealSearch) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    setFullyLoaded(false)
    setMembers([])

    setLoading(true)
    auth.getSealMembers(sealSub, { ...search }, abortControllerRef.current.signal)
    .then(({ data }) => {
      if (data.items.length < 10) setFullyLoaded(true)
      setMembers(data.items)
      bookmarkRef.current = data.bookmark
    })
    .finally(() => setLoading(false))
    .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [abortControllerRef])

  useEffect(() => {
    if (!userSub) return
    auth.getSealMembers(sealSub, { }, abortControllerRef.current.signal)
      .then(({ data: sealList }) => {
        if (sealList.items.length < 10 || sealList.bookmark === '') setFullyLoaded(true)
        setMembers(sealList.items)
        bookmarkRef.current  = sealList.bookmark
      })
    .finally(() => setLoading(false))
    .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [userSub])

  return {
    members,
    loading,
    setLoading,
    fullyLoaded,
    error,
    setMembers,
    getMoreMembers,
    searchMembers
  }
}
