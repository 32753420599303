/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode } from "react";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { useParams } from "react-router-dom";
import SealsContext from "./SealContext";
import useFetchMembers from "./useFetchMembers";

interface Props {
  children: ReactNode
}

export default function SealsProvider({ children }: Props) {
  const { sealId: sealSub } = useParams<{ sealId: string }>();
  const {
    members,
    loading,
    fullyLoaded,
    error,
    setLoading,
    setMembers,
    getMoreMembers,
    searchMembers
  } = useFetchMembers(sealSub)

  if (error || !sealSub) return <ErrorPage errorMessage={error || 'No seal id'} />
  return (
    <SealsContext.Provider 
      value={{
        members,
        loading,
        fullyLoaded,
        sealSub,
        setLoading,
        setMembers,
        getMoreMembers,
        searchMembers
      }}
    >
      {children}
    </SealsContext.Provider>
  )
}
