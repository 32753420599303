import { Button, Form, FormSelect, Input, Modal, useForm } from "@sis-lab/web-ui-components";
import React, { useContext, useState } from "react";
import { useI18nContext } from "i18n/i18n-react";
import auth from "apis/auth";
import SealContext from "pages/SealPage/components/SealContext/SealContext";
import styles from './NewMemberModal.module.scss'

interface Props {
  open: boolean
  onClose: () => void
}

export default function NewMemberModal({ open, onClose }: Props) {
  const [loading, setLoading] = useState(false)
  const { setMembers, members, sealSub } = useContext(SealContext)
  const { LL } = useI18nContext()
  const form = useForm()

  const addMember = async () => {
    const sub = form.getValues('sub')
    setLoading(true)
    auth.addSealMember(sealSub, sub)
      .then(({ data }) => setMembers([data, ...members]))
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title='Add member to the seal'
      footer={null}
    >
      <Form formHook={form} className={styles.form}>
        <FormSelect
          name='memberSub'
          placeholder='Select member'
          options={[]}
          isDisabled
        />
        <Input name='sub' placeholder='Enter member sub' />
      </Form>

      <div className={styles.modalFooter}>
        <Button
          onClick={onClose}
          type='secondary'
          children={LL.commonButtons.cancel()}
        />
        <Button
          disabled={loading}
          onClick={addMember}
          children={LL.commonButtons.create()}
        />
      </div>
    </Modal>
  )
}
