/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import { Button, Card, CardHeader, Form, Input, Select, message, useForm } from '@sis-lab/web-ui-components';
import { eIdPrepareDocument, eIdGetDocument, SupportedCountries, SigningMethod, MethodConfig } from 'apis/contract';
import Signature from '@eid-easy/signature-js'
import { useHistory } from 'react-router-dom';
import countryLookup from 'country-code-lookup';

import extractDocumentInfo from 'components/CorpoFilePreview/components/extractDocumentInfo';
import { downloadBlobFile, getAbFingerprint } from 'utils';
import axios from 'axios';
import styles from './EidEasySignCard.module.scss';
import DocumentContext from '../DocumentContext/DocumentContext';
import useCreateDocument from '../../../DocumentsPage/components/NewDocumentModal/components/useCreateDocument';
import config from '../../../../config';

interface Props {
  fingerprint: string
}

const signWithEidEasy = async (
    documentId: string, country: SupportedCountries, signingMethod: SigningMethod,
    onSuccess: () => any, onFail: () => any
  ) => {
  try {
    const signature = new Signature({
      onSuccess: async () => {
        console.log('User has successfully signed the document');
        await onSuccess()
      },
      onFail: async (error: any) => {
        console.log('Signing failed');
        console.log(error)
        await onFail();
      },
    });

    signature.start({
      clientId: config.eIdEasy.clientId,
      docId: documentId,
      actionType: signingMethod,
      country,
      // inputValues: { // optional
      //     email: 'dummy@dummy.it',
      //     username: 'testuser',
      //     phone: '+37212345678',
      //     idcode: '987654321',
      // },
    });
  } catch (error) {
    console.log(error)
  }
}

function getActionTypesByCountry(chosenCountry: SupportedCountries, methodConfigs: MethodConfig[]): SigningMethod[] {
    const actionTypes: SigningMethod[] = methodConfigs
        .filter((methodConfig: MethodConfig) =>
            methodConfig.supported_countries.includes(chosenCountry))
        .map((methodConfig: MethodConfig) => methodConfig.action_type);

    return actionTypes;
}

export default function EidEasySignCard({ fingerprint }: Props) {
  const { document, fetchDocument } = useContext(DocumentContext)

  const form = useForm();
  const history = useHistory();
  const [createDocument] = useCreateDocument([{ fingerprint }], [], true);
// 
  const [loading, setLoading] = useState(false);
  const [documentAb, setDocumentAb] = useState<ArrayBuffer>();
  const [documentId, setDocumentId] = useState('');

  const [country, setCountry] = useState<SupportedCountries>('LT');
  const [signingMethod, setSigningMethod] = useState<SigningMethod>();
  const [signingMethodOptions, setSigningMethodOptions] = useState<SigningMethod[]>([]);
  const [countryOptions, setCountryOptions] = useState<SupportedCountries[]>([]);
  const [signingConfigs, setSigningConfigs] = useState<MethodConfig[]>([]);

  const detectCountry = async (supportedCountries: SupportedCountries[]) => {
    const ipResp = await axios.get("https://ipapi.co/json/");
    return supportedCountries.includes(ipResp.data.country_code) ? ipResp.data.country_code : 'LT' as SupportedCountries;
  }

  const onUpload = async () => {
    if (!documentAb) return;

    setLoading(true)
    const data: any = {
      fingerprint: getAbFingerprint(documentAb),
      title: document?.title,
      type: document?.type,
      format: document?.format,
      notes: 'Signed with eIdEasy',
    };
    const fileData = Array.from(new Uint8Array(documentAb));

    try {
      await createDocument(data, fileData)
      history.push(`/documents/${data.fingerprint}`);
      window.location.reload();
    } catch (error) {
      console.log(error)
      message.error('Some error occured during upload')
    }
    setLoading(false);
  }

  const onPrepare = async () => {
    setLoading(true)
    try {
      const prepResp = await eIdPrepareDocument(fingerprint);
      setDocumentId(prepResp.data.doc_id);
      setSigningConfigs(prepResp.data.method_configs);

      const countries = [...new Set(
          prepResp.data.method_configs
              .reduce((c: SupportedCountries[], methodConfig) => [
                  ...c,
                  ...methodConfig.supported_countries
              ], [])
      )];
      const detectedCountry = await detectCountry(countries);
      setCountry(detectedCountry);
      setCountryOptions(countries.sort());

      const signingOptions = getActionTypesByCountry(detectedCountry, prepResp.data.method_configs);
      setSigningMethod(signingOptions[0]);
      setSigningMethodOptions(signingOptions);
    } catch (error) {
      message.error('Some error occured during preparation')
    }
    setLoading(false)
  }

  const onSuccess = async () => {
    setLoading(true)
    try {
      const fileResp = await eIdGetDocument(fingerprint, documentId);
      const [blob, fileName] = extractDocumentInfo(fileResp);
      setDocumentAb(fileResp.data);
      downloadBlobFile(blob, fileName);
      await fetchDocument()
    } catch (error) {
      message.error('Some error occured during onSuccess')
    }
    setLoading(false)
  }

  const onFail = () => {
    setLoading(false)
    message.error('Some error occured during signing')
  }

  const onSign = async () => {
    setLoading(true)
    try {
      if (country && signingMethod)
        await signWithEidEasy(documentId, country, signingMethod, onSuccess, onFail);
    } catch (error) {
      setLoading(false)
      message.error('Some error occured during signing')
    }
  }

  return (
    document?.format === 'pdf' ? (
      <Card size='small' loading={loading}>
        <Form className={styles.card} formHook={form} size='medium'>
          <CardHeader title='Sign with eIdEasy' />
          { documentId && !documentAb && country &&
            <>
            <Button children='Sign with eIdEasy' type='primary' onClick={onSign} />
            <br />
              <Input
                disabled
                name='documentId'
                title='Document id inside eIdEasy'
                // tooltip={LL.commonFields.fingerprint.tooltip()}
                value={documentId}
              />
            <br />
              <Select
                name='provider'
                title='Choose country'
                isMulti={false}
                isClearable={false}
                value={{
                  value: country, label: country !== 'WORLD' ? countryLookup.byIso(country)?.country || country : 'WORLD'
                }}
                tooltip='To do'
                options={
                  countryOptions.map(c => ({ value: c, label: c !== 'WORLD' ? countryLookup.byIso(c)?.country || c : 'WORLD' }))
                }
                onChange={option => {
                if (option?.value !== country) {
                    setCountry(option?.value as SupportedCountries);
                    const signingOptions = getActionTypesByCountry(option?.value as SupportedCountries, signingConfigs);
                    setSigningMethod(signingOptions[0]);
                    setSigningMethodOptions(signingOptions);
                  }
                }}
              />
              <Select
                name='provider'
                title='Choose signing method'
                isMulti={false}
                isClearable={false}
                value={{ value: signingMethod || signingMethodOptions[0], label: signingMethod || signingMethodOptions[0] }}
                tooltip='To do'
                options={signingMethodOptions.map(s => ({ value: s, label: s }))}
                onChange={option => setSigningMethod(option?.value as SigningMethod)}
              />
            </>
          }
          { !documentId &&
            <Button children='Prepare the document for eIdEasy' type='secondary' onClick={onPrepare} />
          }
          { documentAb &&
            <Button children='Upload signed document to Corposign' type='primary' onClick={onUpload} />
          }
        </Form>
      </Card>
    ) : null
  )
}
