export const EDELIVERY_PROVIDERS = ['eDeliveryLT', 'SABIS'] as const;

export type EdeliveryProvider = typeof EDELIVERY_PROVIDERS[number];

export type EdeliveryMessage = {
  senderPeppolId: string
  messageId: string
  message: string | null
};

export type LogRecord = {
  id: string,
  details?: string,
  timestamp: string,
  status: 'SUCCESS' | 'ERROR',
  prmId?: string
  documentId?: string,
  processTypeCode: string
};

export type LastReceivedLog = LogRecord[];

export type MessageStatus = {
  status: string
  nonRepudiationInformation: string,
  signalId: string
  errors?: string[]
  messageId: string
}

export type EdeliveryConfig = {
  provider: EdeliveryProvider
  proxyId?: string
  peppolId: string
  apiToken: string
  validateMessages: boolean
  notificationEmail?: string
  sabisClientId?: string
  sabisClientCode?: string
}

export type EdeliveryConfigUpdate = {
  provider: EdeliveryProvider
  apiToken: string
  validateMessages: boolean
  notificationEmail?: string
  sabisClientId?: string
  sabisClientCode?: string
}