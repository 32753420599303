import { useContext, useEffect, useState } from "react";
import auth, { Seal } from "apis/auth";
import SealContext from "../SealContext/SealContext";


export default function useFetchSeal() {
  const [seal, setSeal] = useState<Seal>()
  const { sealSub } = useContext(SealContext)

  useEffect(() => {
    auth.getSeal(sealSub)
      .then(({ data }) => setSeal(data))
  }, [])

  return seal
}
