import { Avatar, Button, Caption, Label } from '@sis-lab/web-ui-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useI18nContext } from 'i18n/i18n-react';
import { ApplicationState } from 'modules';
import SignModal from './components/SignModal';
import styles from './SignRow.module.scss';

interface Props {
  fingerprint: string
  hidden: boolean
}


export default function SignRow({ fingerprint, hidden }: Props) {
  const [action, setAction] = useState<'accept' | 'reject'>();
  const { LL } = useI18nContext();
  const user = useSelector((state: ApplicationState) => state.authentication.user)

  return (
    <div className={[styles.row, hidden && styles.hidden].join(' ')}>
      <Avatar width={40} name={user?.sub} />
      <div className={styles.nameColumn}>
        <Label>{user?.firstName} {user?.lastName} {`(${user?.username})`}</Label>
        <Caption small>{user?.email}</Caption>
      </div>
      <div className={styles.signColumn}>
        <Button children={LL.participantTab.reject()} type='secondary' state='warning' onClick={() => setAction('reject')}/>
        <Button children={LL.participantTab.accept()} onClick={() => setAction('accept')}/>
      </div>
      <SignModal
        action={action}
        fingerprint={fingerprint}
        onCancel={() => setAction(undefined)}
      />
    </div>
  ); 
}
