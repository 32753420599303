import { Card, CardHeader, Checkbox, Form, Input, Select, Skeleton, TextArea, useForm } from "@sis-lab/web-ui-components";
import React, { useEffect, useState } from "react";
import { useI18nContext } from "i18n/i18n-react";
import EditFooter from "../../../PaymentsTab/components/EditFooter/EditFooter";
import usePeppolConfig from "./usePeppolConfig";
import styles from './PeppolConfigCard.module.scss';
import { EDELIVERY_PROVIDERS, EdeliveryProvider } from "../../../../../../apis/eDelivery";

export default function PaypolConfigCard() {
  const [config, loading, missingConfig, updateConfig] = usePeppolConfig();
  const [selectedProvider, setSelectedProvider] = useState<EdeliveryProvider>('eDeliveryLT');
  const [inEdit, setInEdit] = useState(false);
  const [validateMessages, setValidateMessages] = useState(false);
  const { LL } = useI18nContext();
  const form = useForm();

  const showSkeleton = !config && loading && !form.formState.isDirty;

  useEffect(() => {
    if (!config) return;
    form.clearErrors()
    form.setValue('peppolId', config.peppolId)
    form.setValue('proxyId', config.proxyId)
    form.setValue('email', config.notificationEmail)
    form.setValue('apiToken', config.apiToken)
    form.setValue('sabisClientId', config.sabisClientId)
    form.setValue('sabisClientCode', config.sabisClientCode)
    setValidateMessages(config.validateMessages);
    setSelectedProvider(config.provider);
  }, [config, inEdit])

  const startUpdate = async () => {
    form.clearErrors()
    const fieldValues = form.getValues()
    await updateConfig({
      provider: selectedProvider,
      sabisClientId: fieldValues.sabisClientId,
      sabisClientCode: fieldValues.sabisClientCode,
      validateMessages,
      apiToken: fieldValues.apiToken,
      notificationEmail: fieldValues.email,
    })
    setInEdit(false)
  }

  const SabisFields = <>
    <Input
      name='sabisClientId'
      title='Sabis Client Id'
      disabled={loading || !inEdit}
    />
    <Input
      name='sabisClientCode'
      title='Sabis Client Code'
      disabled={loading || !inEdit}
    />
  </>

  return (
    <Card className={styles.configCard}>
      <CardHeader title={LL.peppolTab.configTitle()} />
      {showSkeleton && <Skeleton count={4} style={{ marginBottom: '20px' }} />}
      {missingConfig && LL.peppolTab.missingConfig()}
      <Form
        disabled={!inEdit}
        formHook={form}
        className={[styles.cardBody, (missingConfig || showSkeleton) && styles.hidden].join(' ')}
      >
        <Select
          name='provider'
          title={LL.paymentsTab.providerSelect.title()}
          isMulti={false}
          isClearable={false}
          // defaultValue={{ value: 'eDeliveryLT', label: 'eDeliveryLT' }}
          value={{value: selectedProvider, label: selectedProvider}}
          // tooltip='To do'
          style={{ display: showSkeleton ? 'none' : undefined }}
          options={EDELIVERY_PROVIDERS.map(provider => ({ value: provider, label: provider }))}
          onChange={option => setSelectedProvider(option?.value as EdeliveryProvider)}
          isDisabled={loading || !inEdit}
        />
        <Input
          name='peppolId'
          title={LL.peppolTab.peppolId.title()}
          disabled
        />
        <Input
          name='proxyId'
          title={LL.peppolTab.proxyId.title()}
          disabled
        />
        <Input
          name='email'
          title={LL.peppolTab.notificationEmail.title()}
          disabled={loading || !inEdit}
        />
        { selectedProvider === 'SABIS' && SabisFields }
        <TextArea
          name='apiToken'
          title={LL.peppolTab.apiToken.title()}
          bodyClassName={styles.apiTokenField}
          disabled={loading || !inEdit}
        />
        <Checkbox
          disabled={!inEdit}
          className={styles.checkboxWrapper}
          checked={validateMessages}
          onChange={() => setValidateMessages(!validateMessages)}
          // className={selectedDocument.completed ? styles.hidden : styles.storeCheckbox}
          children='Validate Messages'
          tooltip='Validate uploaded XML messages before sending them to Peppol'
        />
      </Form>
      {!missingConfig && <EditFooter
        inEdit={inEdit}
        onChange={setInEdit}
        loading={loading}
        onSumbit={startUpdate}
      />}
    </Card>
  )
}
