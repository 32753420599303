import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "modules/authentication/actions";
import { useI18nContext } from "i18n/i18n-react";
import errorSvg from 'images/error.svg'
import styles from './ErrorPage.module.scss'

interface Props {
  errorMessage: string
  errorDetails?: string
}

export default function ErrorPage({ errorMessage, errorDetails }: Props) {
  const { LL } = useI18nContext()
  const dispatch = useDispatch();
  const logout = () => logoutAction()(dispatch)
  
  return (
    <div className={styles.page}>
      <img src={errorSvg} alt='error' />
      <h2>{LL.errorPage.message()} <Link onClick={logout} to='/login'>{LL.commonButtons.logout()}</Link></h2>
      <p>{errorMessage}</p>
      <p>{errorDetails}</p>
    </div>
  )
}

