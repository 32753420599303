const {
  REACT_APP_CONTRACT_API_URL,
  REACT_APP_AUTH_API_URL,
  REACT_APP_EID_EASY_CLIENT_ID,
  REACT_APP_GATEWAY_APP_URL,
  REACT_APP_PRM_URL,
  REACT_APP_PROJECT_COMMIT_DATE,
  REACT_APP_PROJECT_COMMIT_SHORT_SHA,
  REACT_APP_STORAGE_API_URL,
  REACT_APP_XML_API_URL,
  REACT_APP_EDELIVERY_API_URL,
  REACT_APP_AI_API_URL,
  REACT_APP_ISSUER_API_URL,
  REACT_APP_ISSUER_API_VERIFIER_WEBSOCKET_URL,
} = process.env;

const { protocol, hostname } = window.location;

if (!REACT_APP_ISSUER_API_VERIFIER_WEBSOCKET_URL)
  throw new Error('REACT_APP_ISSUER_API_VERIFIER_WEBSOCKET_URL is not specified')

// const parseWebsocketUrl = (url: string) => url.replace('http://', '').replace('https://', '');

export default {
  prmUrl: REACT_APP_PRM_URL || 'https://api.prm.sis.lt/v1',
  projectCommitDate: REACT_APP_PROJECT_COMMIT_DATE || '',
  projectCommitShortSHA: REACT_APP_PROJECT_COMMIT_SHORT_SHA || '',
  contractApiUrl: REACT_APP_CONTRACT_API_URL || `${protocol}//api.${hostname}/contract/v1`,
  authApiUrl: REACT_APP_AUTH_API_URL || `${protocol}//api.auth.${hostname}/v1`,
  gatewayAppUrl: REACT_APP_GATEWAY_APP_URL || 'https://gateway.corposign.net',
  eDeliveryApiUrl: REACT_APP_EDELIVERY_API_URL || 'https://dv.edelivery.sis.lt/v1',
  keycloakUrl: 'https://id.sis.lt/realms/id/protocol/openid-connect/token',
  storageApiUrl: REACT_APP_STORAGE_API_URL || 'https://api.test.corposign.net/storage/v1',
  eIdEasy: {
    clientId: REACT_APP_EID_EASY_CLIENT_ID,
  },
  oidc: {
    grantType: {
      authentication: 'password',
      refresh: 'refresh_token',
    },
    user: {
      aud: 'corposign-api',
      iss: 'https://sso.corposign.net/auth/realms/corposign',
    },
  },
  xmlApi: {
    url: REACT_APP_XML_API_URL || "https://xml.sis.lt/api/public",
  },
  aiApi: {
    url: REACT_APP_AI_API_URL || "https://ai.dv.corposign.net/api/v1"
  },
  loginWithVPWebSocketURL: 'wss://api-v2.did.sis.lt/v1/ws',
  services: {
    issuerApi: {
      url: REACT_APP_ISSUER_API_URL || "https://issuer.corposign.net/api/v1",
      verifierWs: REACT_APP_ISSUER_API_VERIFIER_WEBSOCKET_URL
    }
  }
};
