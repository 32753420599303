import { IDocumentDetailed, ISignatureDetailed } from "apis/contract";

export const getHasSigned = (signatures?: ISignatureDetailed[], sub?: string) => signatures?.some(
  signature => signature.creator.sub === sub
)

export const getIsInvited = (document?: IDocumentDetailed, sub?: string, email?: string) => {
  const invited = document?.invites.some(invite =>
    invite.sub ? invite.email === email && invite.sub === sub : invite.email === email
  )
  const owner = document?.creator.sub === sub;
  return owner || invited
}
