import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Card, Tabs, Tab } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { ApplicationState } from 'modules';
import Counter from 'components/Counter/Counter';
import HistoryTab from './HistoryTab/HistoryTab';
import InviteModalButton from './InviteModal/InviteModal';
import ParticipantsTab from './ParticipantsTab/ParticipantsTab';
import DocumentContext from '../DocumentContext/DocumentContext';
import styles from './SignaturesCard.module.scss';

interface Props {
  isValidator: boolean
  fingerprint: string
}


export default function SignaturesCard({ isValidator, fingerprint }: Props) {
  const user = useSelector((state: ApplicationState) => state.authentication.user)
  const { document, updating, fetching } = useContext(DocumentContext)
  const { LL } = useI18nContext()
  const isRejected = document?.history.some(event => event.action === 'reject')

  return (
    <Card size='small' className={styles.tabCard} loading={updating || (fetching && !!document)}>
      {document && <InviteModalButton
        fingerprint={fingerprint}
        btnClassName={(!isValidator) ?  styles.inviteBtn : styles.hidden}
        // btnClassName={(document.creator.sub === user?.sub && !isValidator) ?  styles.inviteBtn : styles.hidden}
      />}
      <Tabs
        size='medium'
        bodyClassName={styles.tabBody}
        tabsClassName={[styles.tabHeader, isRejected && styles.rejectHeader].join(' ')}
      >
        <Tab
          label={LL.participantTab.signeesTab()}
          element={<ParticipantsTab isValidator={isValidator} isOwner={document?.creator.sub === user?.sub} />}
          suffix={(!isRejected && !isValidator && document) ?
            <Counter
              invites={document?.invites || []}
              signatures={document?.signatures || []}
            /> : undefined
          }
        />
        <Tab label={LL.participantTab.historyTab()} element={<HistoryTab />} />
      </Tabs>
    </Card>
  )
}
